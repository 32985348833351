import axios from 'axios'
import { ref } from '@vue/composition-api'
import formError from './formErrorHandler/formError'

const crud = () => {
  const responseData = ref('')
  const responseMessage = ref('')
  const responseMessageStatus = ref('')
  const responseMessageStatusNo = ref(null)

  const store = async (uri, _loading, formData, formErrorFields) => {
    const loading = _loading
    try {
      loading.value = true
      const response = await axios.post(uri, formData)
      responseData.value = response.data
      responseMessage.value = response.data.message
      responseMessageStatus.value = 'success'
      loading.value = false
    } catch (error) {
      loading.value = false
      if (error.response.status !== undefined) {
        responseMessageStatusNo.value = error.response.status
        if (error.response.status === 422) {
          formError().handle(error, formErrorFields)
        } else {
          responseMessage.value = error.response.data.message
        }
      }
      responseMessageStatus.value = 'error'
    }
  }

  const update = async (uri, _loading, formData, formErrorFields, _responseStorage = undefined) => {
    const responseStorage = _responseStorage
    const loading = _loading
    try {
      loading.value = true
      const response = await axios.put(uri, formData)
      responseData.value = response.data
      responseMessage.value = response.data.message
      responseMessageStatus.value = 'success'
      loading.value = false

      if (responseStorage !== undefined) responseStorage.value = response.data
    } catch (error) {
      loading.value = false
      if (error.response.status !== undefined) {
        responseMessageStatusNo.value = error.response.status
        if (error.response.status === 422) {
          formError().handle(error, formErrorFields)
        } else {
          responseMessage.value = error.response.data.message
        }
      }
      responseMessageStatus.value = 'error'
    }
  }

  const remove = async (uri, _loading) => {
    const loading = _loading
    try {
      loading.value = true
      const response = await axios.delete(uri)
      responseData.value = response.data
      responseMessage.value = response.data.message
      responseMessageStatus.value = 'success'
      loading.value = false
    } catch (error) {
      loading.value = false
      if (error.response.status !== undefined) {
        responseMessageStatusNo.value = error.response.status
        if (error.response.status === 404) {
          responseMessage.value = 'Resource not found'
        } else {
          responseMessage.value = error.response.data.message
        }
      }
      responseMessageStatus.value = 'error'
    }
  }

  const updateNoData = async (uri, _loading) => {
    const loading = _loading
    try {
      loading.value = true
      const response = await axios.put(uri)
      responseData.value = response.data
      responseMessage.value = response.data.message
      responseMessageStatus.value = 'success'
      loading.value = false
    } catch (error) {
      loading.value = false
      if (error.response.status !== undefined) {
        responseMessageStatusNo.value = error.response.status
        responseMessage.value = error.response.data.message
      }
      responseMessageStatus.value = 'error'
    }
  }

  return {
    responseMessageStatusNo,
    responseMessageStatus,
    responseMessage,
    responseData,
    store,
    update,
    updateNoData,
    remove,
  }
}

export default crud
